
















import { Component } from 'vue-property-decorator'

import { ShippingMethod } from '../../../../contexts'

import { AbstractStepPreview } from '../AbstractStepPreview'
import { shippingIcon } from '../Shippings/Shippings.helpers'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'ShippingStepPreview'
})
export class ShippingStepPreview extends AbstractStepPreview {
  private method: ShippingMethod | null = this.payload?.shipping ?? null

  public get icon (): string | null {
    if (!this.method) {
      return null
    }

    return shippingIcon[this.method.carrierCode]
  }

  public get name (): string | null {
    if (!this.method) {
      return null
    }

    return this.$t(`front.checkout.organisms.ShippingStep.method.${this.method.carrierCode}.title`)
      .toString()
  }

  public get price (): number {
    return this.method ? this.method.price.value : 0
  }
}

export default ShippingStepPreview
